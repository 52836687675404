import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import '../components/style.css'

const success = (props) => {

  const siteTitle = 'Thank You For Contacting Us'
  const siteDescription = 'Your contact form submission has been submitted successfuly. We will contact you shortly about your upcoming project.'

  return (
    <Layout location={props.location} title={siteTitle}>
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      meta={[{ name: 'description', content: siteDescription }]}
      title={siteTitle}
    />
    <h5 style={{textAlign: 'center',}}>You have taken the first steps to digital success. We will contact you shortly about your project.</h5>
    </Layout>
  )
}

export default success
